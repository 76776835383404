<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { SweetModal } from 'sweet-modal-vue'
import VuePdfApp from 'vue-pdf-app'
import { debounce } from 'vue-debounce'
import PaginationCustom from '@/components/table/Pagination.vue'
import ProyekDetail from '@/components/oss/ProyekDetail.vue'
import IzinLama from '@/components/oss/IzinLama.vue'
import appData from '@/data'
import 'vue-pdf-app/dist/icons/main.css'

export default {
   name: 'OssBidangUsaha',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
      ProyekDetail,
      IzinLama,
      SweetModal,
      VuePdfApp,
   },
   data() {
      return {
         pdf: '',
         loadingPdf: false,
         idProyek: '',
         loading: true,
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama / NIB',
               field: 'nib',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Bidang Usaha / Alamat',
               field: 'kbli',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Nama & Data Usaha',
               field: 'data_usaha',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Kategori',
               field: 'kd_resiko',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center',
            },
         ],
      }
   },

   computed: {
      ...mapState({
         myData: state => state.OssBidangUsaha.items,
         myCount: state => state.OssBidangUsaha.countAll,
      }),
      jenisPerusahaan() {
         const data = appData.jenisPerusahaan
         return status => (data[status] ? data[status] : status)
      },
      skalaUsaha() {
         const data = appData.skalaUsaha
         return status => (data[status] ? data[status] : status)
      },
      statusPM() {
         const data = appData.statusPm
         return status => (data[status] ? data[status] : status)
      },
      statusResiko() {
         const data = appData.statusResiko
         return status => (data[status] ? data[status] : '-')
      },
      sektorCode() {
         const data = appData.sektor
         return status => (data[status] ? data[status] : status)
      },
      statusProyek() {
         const data = appData.statusProyek
         return status => (data[status] ? data[status] : status)
      },
      momentDateTimeUtc() {
         return datetime => this.$moment(datetime).utc().format('HH:mm | DD-MM-YYYY')
      },
   },
   watch: {
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'OssBidangUsaha/getData',
         cetakNib: 'OssNib/cetakNib',
         cetakIzinLama: 'OssBidangUsaha/cetakIzinLama',
      }),
      ...mapMutations({
         setIdProyek: 'OssBidangUsaha/SET_ID_PROYEK',
      }),
      onDetailProyek(idProyek) {
         this.setIdProyek(idProyek)
         this.$bvModal.show('modal-detail')
      },
      onListIzinLama(idProyek) {
         this.setIdProyek(idProyek)
         this.$bvModal.show('modal-izin-lama')
      },
      onCetakNib(nib) {
         this.loadingPdf = true
         this.cetakNib(nib).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },
      onCetakIzinLama(id) {
         this.loadingPdf = true
         this.cetakIzinLama(id).then(res => {
            const blob = new Blob([res.data])
            this.pdf = URL.createObjectURL(blob)
            // URL.revokeObjectURL(objectURL)
            this.$refs.modalPdf.open()
            this.loadingPdf = false
         }, () => {
            this.loadingPdf = false
         })
      },

      // VUE GOOD TABLE
      async loadItems() {
         this.loading = true
         await this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Bidang Usaha">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                        @input="onSearch"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button v-b-toggle.collapse-filter variant="outline-secondary" size="sm">
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button variant="outline-secondary" size="sm" @click="loadItems()">
                        <feather-icon icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown variant="outline-secondary" right text="Menu" size="sm">
                        <b-dropdown-item @click="onFilter()">
                           Filter
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <!-- Column: Name -->
               <span v-if="props.column.field == 'nib'">
                  <b>{{ (props.row.nama_perusahaan) }}</b>
                  <br>
                  <hr>
                  <b class="text-primary">{{ (props.row.nib) }}</b>
                  <hr>
                  <small>{{ jenisPerusahaan(props.row.jenis_perusahaan) }}</small>
                  <hr>
                  <small>{{ statusPM(props.row.status_penanaman_modal) }}</small>
                  <hr>
                  <small>{{ statusPM(props.row.id_proyek) }}</small>
               </span>

               <span v-if="props.column.field == 'kbli'">
                  <b>KBLI : {{ props.row.kbli }}</b>
                  <hr>
                  <small>{{ props.row.kbli_nama || '-' }}</small>
                  <hr>
                  <small>
                     Alamat :
                     <b>{{ props.row.alamat_usaha }}</b>
                  </small>
                  <hr>
                  <small>
                     Desa/kel:
                     <b>{{ props.row.kelurahan }}</b>
                     <br>Kec:
                     <b>{{ props.row.kecamatan }}</b>
                  </small>
               </span>

               <span v-if="props.column.field == 'data_usaha'">
                  <table class="borderless">
                     <tr>
                        <td>
                           <small>Nama</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <small>
                              <b>{{ props.row.nama_proyek }}</b>
                           </small>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <small>Modal</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <b class="text-danger">
                              <small>{{ (props.row.jumlah_investasi) | formatNumber }}</small>
                           </b>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <small>TKI</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <small>{{ (props.row.jumlah_tenaga_kerja) }}</small>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <small>TKA</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <small>{{ (props.row.jumlah_tenaga_kerja_asing) }}</small>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <small>Migrasi</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <small>{{ (props.row.flag_migrasi) }}</small>
                        </td>
                     </tr>
                     <tr>
                        <td>
                           <small>Date</small>
                        </td>
                        <td>
                           <small>&nbsp;:&nbsp;</small>
                        </td>
                        <td>
                           <small>{{ momentDateTimeUtc(props.row.latest_date) }}</small>
                        </td>
                     </tr>
                  </table>
               </span>

               <span v-if="props.column.field == 'kd_resiko'">
                  <small>
                     <table class="borderless">
                        <tr>
                           <td>Sektor</td>
                           <td>&nbsp;:&nbsp;</td>
                           <td>
                              <b class="text-danger">{{ sektorCode(props.row.sektor) }}</b>
                           </td>
                        </tr>
                        <tr>
                           <td>Resiko</td>
                           <td>&nbsp;:&nbsp;</td>
                           <td>
                              <b class="text-danger">{{ statusResiko(props.row.kd_resiko) }}</b>
                           </td>
                        </tr>
                        <tr>
                           <td>Skala</td>
                           <td>&nbsp;:&nbsp;</td>
                           <td>{{ skalaUsaha(props.row.kd_skala_usaha) }}</td>
                        </tr>
                     </table>
                  </small>
               </span>

               <span v-if="props.column.field == 'status'">
                  <center>{{ statusProyek(props.row.status) }}</center>
               </span>

               <span v-if="props.column.field == 'action'">
                  <center>
                     <b-button
                        v-b-tooltip.hover.top="'Detail Proyek'"
                        size="sm"
                        block
                        variant="outline-dark"
                        @click="onDetailProyek(props.row.id_proyek)"
                     >
                        <small>Detail</small>
                     </b-button>
                     <b-button
                        size="sm"
                        block
                        variant="outline-dark"
                        @click="onListIzinLama(props.row.id_proyek)"
                     >
                        <small>Izin Lama</small>
                     </b-button>
                     <b-button
                        v-b-tooltip.hover.top="'Cetak NIB'"
                        size="sm"
                        block
                        variant="primary"
                        @click="onCetakNib(props.row.nib)"
                     >
                        <feather-icon icon="FileTextIcon" class="mr-50" />
                        <small>NIB</small>
                     </b-button>
                  </center>
               </span>

               <!-- Column: Common -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-detail"
         scrollable
         size="xl"
         hide-footer
         title="Bidang Usaha Detail"
      >
         <ProyekDetail ref="proyekDetail" />
      </b-modal>

      <b-modal
         id="modal-izin-lama"
         scrollable
         size="lg"
         hide-footer
         title="Izin Lama"
      >
         <IzinLama ref="izinLama" @update:pdf="onCetakIzinLama" />
      </b-modal>

      <sweet-modal ref="modalPdf" modal-theme="light" overlay-theme="dark" width="80%">
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh;">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
.borderless > tr > td {
   border: none;
   padding: 0em;
}
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}
</style>
